export const status = [
  { statusId: 1, statusName: 'Cadastro Pendente', backgroundColor: '#d18b28' },
  {
    statusId: 2,
    statusName: 'Aguardando Aprovação',
    backgroundColor: '#d18b28',
  },
  {
    statusId: 3,
    statusName: 'Aguardando Documentação',
    backgroundColor: '#d18b28',
  },
  { statusId: 4, statusName: 'Aprovado', backgroundColor: '#39854d' },
  { statusId: 5, statusName: 'Em Análise', backgroundColor: '#366199' },
  { statusId: 6, statusName: 'Negado', backgroundColor: '#c21d36' },
  { statusId: 7, statusName: 'Cancelado', backgroundColor: '#c21d36' },
  { statusId: 8, statusName: 'Suspenso', backgroundColor: '#c21d36' },
  { statusId: 9, statusName: 'Inativo', backgroundColor: '#c21d36' },
  { statusId: 10, statusName: 'Sem Oferta', backgroundColor: '#366199' },
  { statusId: 11, statusName: 'Checagem', backgroundColor: '#d18b28' },
  { statusId: 12, statusName: 'Formalização', backgroundColor: '#366199' },
  { statusId: 13, statusName: 'Atraso', backgroundColor: '#d18b28' },
  { statusId: 14, statusName: 'Expirado', backgroundColor: '#366199' },
];

export const statusFinal = [
  { statusId: 4, statusName: 'Aprovado' },
  { statusId: 6, statusName: 'Negado' },
  { statusId: 7, statusName: 'Cancelado' },
  { statusId: 8, statusName: 'Suspenso' },
  { statusId: 9, statusName: 'Inativo' },
  { statusId: 10, statusName: 'Sem Oferta' },
  { statusId: 13, statusName: 'Atraso' },
  { statusId: 14, statusName: 'Expirado' },
];

export const statusCadastro = [
  { statusId: 2, statusName: 'Aguardando Aprovação' },
  { statusId: 3, statusName: 'Aguardando Documentação' },
  { statusId: 1, statusName: 'Cadastro Pendente' },
  { statusId: 5, statusName: 'Em Análise' },
  { statusId: 11, statusName: 'Checagem' },
  { statusId: 12, statusName: 'Formalização' },
];

export const motivesIdsByStatusType = {
  1: [1, 2, 48],
  2: [3],
  3: [9, 10],
  4: [14, 56, 57],
  5: [4, 5, 6, 7, 8],
  6: [18, 33, 34, 20, 19, 35, 36, 37, 38, 55],
  7: [16, 60],
  8: [24, 25],
  9: [17, 61, 62, 63],
  10: [21, 22, 23, 39, 40, 42, 43, 44, 45, 46, 47, 50, 51, 52, 53, 54],
  11: [11],
  12: [29, 13],
  13: [15, 30, 31, 32],
  14: [26, 27, 28, 41],
};

export const motives = [
  { motiveId: 1, motiveName: 'Quiz' },
  { motiveId: 2, motiveName: 'Preenchimento' },
  { motiveId: 3, motiveName: 'Fila' },
  { motiveId: 4, motiveName: 'Pré analise' },
  { motiveId: 5, motiveName: 'Visita - Agendar' },
  { motiveId: 6, motiveName: 'Visita - Agendada' },
  { motiveId: 7, motiveName: 'Comite Interno' },
  { motiveId: 8, motiveName: 'Comite Financiadores' },
  { motiveId: 9, motiveName: 'Sem Documentos' },
  { motiveId: 10, motiveName: 'Correção' },
  { motiveId: 11, motiveName: 'Checagem de Documentos' },
  { motiveId: 12, motiveName: 'Envio de Contratos' },
  { motiveId: 29, motiveName: 'Autorização de Abertura' },
  { motiveId: 13, motiveName: 'Assinatura de Contrato' },
  { motiveId: 14, motiveName: 'FX1 - Em Dia' },
  { motiveId: 15, motiveName: 'FX2 - até 20d' },
  { motiveId: 16, motiveName: 'Cancelado' },
  { motiveId: 17, motiveName: 'Inativo' },
  { motiveId: 30, motiveName: 'FX3 - até 60d' },
  { motiveId: 31, motiveName: 'FX4 - até 120d' },
  { motiveId: 32, motiveName: 'FX5 - WO' },
  { motiveId: 18, motiveName: 'BlackList' },
  { motiveId: 33, motiveName: 'Restritivos PJ' },
  { motiveId: 34, motiveName: 'Restritivos PF' },
  { motiveId: 20, motiveName: 'Restrição em Fomento Comercial' },
  { motiveId: 19, motiveName: 'Risco elevado' },
  { motiveId: 35, motiveName: 'Localização ' },
  { motiveId: 36, motiveName: 'Visita Virtual' },
  { motiveId: 37, motiveName: 'Comite Financiador' },
  { motiveId: 38, motiveName: 'Inconsistência Cadastral' },
  { motiveId: 21, motiveName: 'MEI' },
  { motiveId: 22, motiveName: 'Sacado PF' },
  { motiveId: 23, motiveName: 'Micro Empresa' },
  { motiveId: 39, motiveName: 'Taxa Banco' },
  { motiveId: 40, motiveName: 'Menos de 1 ano' },
  { motiveId: 42, motiveName: 'Não recebe por TED ou Boleto' },
  { motiveId: 43, motiveName: 'Fomento' },
  { motiveId: 44, motiveName: 'Cartão' },
  { motiveId: 45, motiveName: 'Sem Interesse' },
  { motiveId: 46, motiveName: 'Emprestimo' },
  { motiveId: 47, motiveName: 'Contrato' },
  { motiveId: 24, motiveName: 'Nota Fria' },
  { motiveId: 25, motiveName: 'Cliente Frio' },
  { motiveId: 26, motiveName: 'Cadastro Pendente' },
  { motiveId: 27, motiveName: 'Aguardando Docs' },
  { motiveId: 28, motiveName: 'Formalização' },
  { motiveId: 41, motiveName: 'Analise' },
  { motiveId: 48, motiveName: 'WhiteList' },
  { motiveId: 60, motiveName: 'Sem Conhecimento' },
  { motiveId: 61, motiveName: 'Sem Atividade' },
  { motiveId: 62, motiveName: 'Sem Necessidade' },
  { motiveId: 63, motiveName: 'Taxa Alta' },
  { motiveId: 50, motiveName: 'Faturamento Baixo' },
  { motiveId: 51, motiveName: 'Sacado PJ Pequeno Porte' },
  { motiveId: 52, motiveName: 'Cheque' },
  { motiveId: 53, motiveName: 'Sem Contato' },
  { motiveId: 54, motiveName: 'Sem Comprovante de Endereço' },
  { motiveId: 55, motiveName: 'Empresa Inativa, Baixada ou Inapta' },
  { motiveId: 56, motiveName: 'Cadastro'},
  { motiveId: 57, motiveName: 'Crédito'},
];

export const radioStates = {
  cadastro: 'Status Cadastro',
  final: 'Status Final',
};

// export const marketTypes = [
//   { typeId: 1, typeName: 'Leilão' },
//   { typeId: 2, typeName: 'Balcão' },
//   { typeId: 3, typeName: 'Todos' },
// ];

// const businessTypes = [
//   { typeId: 1, typeName: 'Cedente' },
//   { typeId: 2, typeName: 'Financiador' },
//   { typeId: 3, typeName: 'Parceiro' },
// ];

export const marketTypes = [
  {
    value: 1,
    label: 'Leilão',
  },
  {
    value: 2,
    label: 'Balcão',
  },
  {
    value: 3,
    label: 'Exclusão',
  },
  // {
  //   value: 4,
  //   label: 'Balcão FIF',
  // },
];

export const taxasDescription = [
  {
    id: 1,
    title: 'custos',
    description:
      'Todos custos envolvidos no seu processo, serão aplicados sobre todas suas ofertas',
  },
  {
    id: 2,
    title: 'antecipagov',
    description:
      'Operações dentro da plataforma vindas do projeto do governo federal chamado AntecipaGov onde se tem uma operação baseada em empenhos e com o sacado sendo uma instituição federal',
  },
  {
    id: 3,
    title: 'adValorem',
    description:
      'Essa porcentagem é dada ao mês e se refere a uma taxa de administração de cada agente financeiro. Incide sobre o valor total dos vencimentos. Valor sugerido: 0,3% - 1%',
  },
  {
    id: 4,
    title: 'iofValor',
    description:
      'Imposto federal denominado Imposto sobre Operações Financeiras, incide sobre o valor total dos vencimentos. Valor sugerido: 0,38%',
  },
  {
    id: 5,
    title: 'iofPrazo',
    description:
      'Também compõe o tributo federal IOF, mas essa taxa incide sobre cada vencimento e é ponderada com o prazo médio(somado com floating). Valor sugerido: 0,0041%',
  },
  {
    id: 6,
    title: 'prazoFloating',
    description:
      'Esse campo representa dias a serem somados no prazo de cada vencimento com o objetivo de incluir na cobrança da operação o período entre o pagamento e o efetivo recebimento. É considerado para o ponderar a comissão da plataforma também. Valor sugerido: 0 - 5 dias',
  },
  {
    id: 7,
    title: 'taxaCobranca',
    description:
      'Se refere ao custo que se tem por vencimento vindo das instituições bancárias, geralmente referente ao custo de emissão de boletos. Incide sobre cada vencimento. Valor sugerido: R$ 0 (caso os valores caiam em conta consignada, não há boletos no processo)',
  },
  {
    id: 8,
    title: 'taxaOperacao',
    description:
      'Aqui se enquadram quaisquer outras despesas e impostos que incidem sobre a sua operação. Calculado como uma porcentagem sobre o valor total dos vencimentos. Valor sugerido: 0% - 1%',
  },
  {
    id: 9,
    title: 'taxaAnalise',
    description:
      'Esse campo representa o custo relacionado a sua análise de crédito para cada título. Incide sobre o valor total dos vencimentos. Valor sugerido: Até R$30,00 (lembrando que todos títulos já passam por análise)',
  },
  {
    id: 10,
    title: 'SERPRO',
    description:
      'Taxa fixa do SERPRO para operações do AntecipaGov. Essa porcentagem incide sobre o valor total dos vencimentos',
  },
  {
    id: 11,
    title: 'CCB',
    description:
      'Essa é a taxa de bancarização para operações do AntecipaGov. Caso você tenha um processo próprio você pode colocar sua taxa',
  },
];
