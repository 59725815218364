import React, { useState } from "react";
import { useQueryClient, useQuery } from "react-query";

import { TextField, MenuItem } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { BidDialog } from "pages/CadastroTitulo/tabs/Lances";

import { ConfirmButton } from "components/Button";
import { ReactCatchIf, ReactIf, ReactThen } from "components/ReactIf";

import formatReal from "utils/formatReal";
import formatTelefone from "utils/formatTelefone";

import { getDadosEmail } from "services/business";
import { getDrawee } from "services/drawee";
import {
  arremateTituloCedente,
  verificarTituloArrematado,
} from "services/invoice";
import {
  emailTituloArrematadoCed,
  emailTituloArrematadoFin,
  showError,
} from "services/sendgrid";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import { useMaturitys } from "hooks/useMaturitys";
import { useModal } from "hooks/useModal";

import "styles/risk.scss";

const Lance = (props) => {
  const {
    title,
    rangeObjects,
    data,
    disabled,
    format,
    invoiceId,
    invoice,
    buyerId,
    seller,
  } = props;

  const { isCedente, isAdmin, user } = useAuth();
  const { toast } = useToast();
  const { maturitys } = useMaturitys(invoiceId);
  const { openModal } = useModal();

  const [bidDetailsModalOpen, setBidDetailsModalOpen] = useState(false);
  const [isSendingAcceptOffer, setIsSendingAcceptOffer] = useState(false);

  const validateMaturitiesDates = () => {
    const hasNegative = maturitys.some((maturity) => maturity.dias < 0);
    return !hasNegative;
  };

  const showAcceptBid = () => {
    if (!maturitys || !data) return false;

    const hasValidDates = validateMaturitiesDates();
    const isBestBid = data.statusId === 1;
    return hasValidDates && isBestBid && isCedente();
  };

  const sendEmailBuyerAndSeller = async () => {
    const dadosEmailSeller = await getDadosEmail(
      invoice.businessId,
      invoice.userId
    );
    const dadosEmailBuyer = await getDadosEmail(buyerId, data.userBuyerId);

    const _parcelas = maturitys.filter((x) => x.invoiceMaturityId != undefined);
    const dataValorParcelas = [];
    for (let index = 0; index < _parcelas.length; index++) {
      const arrDate = _parcelas[index].data.split("/");
      const keyDate = `${arrDate[2]}${arrDate[1]}${arrDate[0]}`;
      const element = {
        key: keyDate,
        data: _parcelas[index].data,
        valor: _parcelas[index].valor,
      };
      dataValorParcelas.push(element);
    }
    dataValorParcelas.sort((a, b) =>
      a.key > b.key ? 1 : b.key > a.key ? -1 : 0
    );
    const drawee = await getDrawee(invoice.invoiceId);
    const payloadEnvioArremate = {
      publico: 1,
      empresa: dadosEmailSeller[0].origemWL,
      email_destinatario: dadosEmailSeller[0].userEmail,
      nome_cedente: dadosEmailSeller[0].businessName,
      numeroNF: invoice.id,
      cnpj_cedente: dadosEmailSeller[0].country_identity,
      nome_sacado: drawee.corporateName,
      nome_financiador: dadosEmailBuyer[0].businessName,
      valor_parcelas: invoice.maturitiesValue,
      valor_liquido: invoice.bidNetValue,
      data_vencimento_valor: dataValorParcelas,
      CET: invoice.bidNetFactor.toFixed(3),
      valor_desagio: invoice.bidDiscountValue.toFixed(2),
      user_email: dadosEmailSeller[0].userEmail,
    };

    try {
      if (dadosEmailSeller[0].businessEmail) {
        await emailTituloArrematadoCed({
          ...payloadEnvioArremate,
          email_destinatario: dadosEmailSeller[0].businessEmail,
        });
      }

      await emailTituloArrematadoCed(payloadEnvioArremate);
    } catch {
      showError();
    }

    const payloadEnvioArremateFin = {
      publico: 2,
      empresa: dadosEmailBuyer[0].origemWL,
      email_destinatario: dadosEmailBuyer[0].userEmail,
      nome_cedente: dadosEmailSeller[0].businessName,
      numeroNF: invoice.id,
      cnpj_cedente: dadosEmailSeller[0].country_identity,
      nome_sacado: drawee.corporateName,
      nome_financiador: dadosEmailBuyer[0].businessName,
      valor_parcelas: invoice.maturitiesValue,
      valor_liquido: invoice.bidNetValue,
      data_vencimento_valor: dataValorParcelas,
      CET: invoice.bidNetFactor.toFixed(3),
      valor_desagio: invoice.bidDiscountValue.toFixed(2),
      user_email: dadosEmailBuyer[0].userEmail,
    };

    try {
      if (dadosEmailBuyer[0].businessEmail) {
        await emailTituloArrematadoFin({
          ...payloadEnvioArremateFin,
          email_destinatario: dadosEmailBuyer[0].businessEmail,
        });
      }

      await emailTituloArrematadoFin(payloadEnvioArremateFin);
    } catch {
      showError();
    }
  };

  const handleAcceptBid = async () => {
    try {
      if (isSendingAcceptOffer)
        return toast("Aceitando Lance", { id: "aceitar-oferta" });
      setIsSendingAcceptOffer(true);

      toast.loading("Aceitando lance", { id: "aceitar-oferta" });

      await arremateTituloCedente(invoice.invoiceId, data.userBuyerId, buyerId);

      toast.success("Lance aceito com sucesso!", { id: "aceitar-oferta" });
      await sendEmailBuyerAndSeller();

      queryClient.invalidateQueries(`is-titulo-arrematado-${data.invoiceId}`);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message, { id: "aceitar-oferta" });
      } else {
        toast.error("Erro ao aceitar lance", { id: "aceitar-oferta" });
      }
    } finally {
      setIsSendingAcceptOffer(false);
    }
  };

  const handleAcceptBidModal = async () => {
    await openModal(
      "warning",
      "Atenção!",
      "Tem certeza que deseja aprovar este lance?",
      handleAcceptBid
    );
  };

  function closeBidDetailsModal() {
    setBidDetailsModalOpen(false);
  }

  function showBidDetailsModal() {
    setBidDetailsModalOpen(true);
  }

  function isSignedUserOwnerOfTheBif() {
    if (!user) return false;

    return user.businessId === data.buyerId;
  }

  function canShowBidDetails() {
    if (isCedente()) return false;

    if (!isSignedUserOwnerOfTheBif() && !isAdmin()) return false;

    return true;
  }

  const [isTituloArrematado, setIsTituloArrematado] = useState(null);

  const queryClient = useQueryClient();

  useQuery(
    `is-titulo-arrematado-${data.invoiceId}`,
    handleLoadIsTituloArrematado,
    {
      onSuccess: (value) => {
        setIsTituloArrematado(value === "s" ? "s" : "n");
      },
      onError: () => {
        toast.error("Erro ao verificar se título foi arrematado");
      },
      staleTime: 1 * 1000 * 10,
    }
  );

  async function handleLoadIsTituloArrematado() {
    const r_response = await verificarTituloArrematado(data.invoiceId);

    return r_response;
  }

  return (
    <div
      className="formContainer"
      style={{
        marginBottom: "30px",
        borderRadius: 8,
        alignContent: "unset",
        alignItems: "center",
      }}
    >
      {title && (
        <div className="containerTitle">
          <h4 style={{ fontFamily: "Poppins, sans-serif" }}>{title}</h4>
        </div>
      )}
      <div style={{ display: "flex", width: "100%" }}>
        {Object.keys(data).length > 0 &&
          rangeObjects.map((obj) => {
            if (obj.isDate) {
              return (
                <div className="container" style={{ marginTop: "-10px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <KeyboardDatePicker
                      helperText=""
                      cancelLabel="Cancelar"
                      disableToolbar
                      locale={ptBR}
                      value={new Date(data[obj.key]) || ""}
                      name={obj.key}
                      variant="outlined"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label={obj.label}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disabled={disabled}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              );
            }

            if (obj.select) {
              return (
                <div className="container">
                  <TextField
                    autoFocus
                    select
                    variant="outlined"
                    label={obj.label}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={data[obj.key.split(";")[1]] || ""}
                    disabled={disabled}
                  >
                    {obj.select.map((item) => (
                      <MenuItem value={item.key}>{item.name}</MenuItem>
                    ))}
                  </TextField>
                </div>
              );
            }

            if (obj.phone) {
              return (
                <div className="container">
                  <TextField
                    id="outlined-basic"
                    defaultValue={data[obj.key]}
                    value={formatTelefone(data[obj.key]) || ""}
                    name={obj.key}
                    label={obj.label}
                    variant="outlined"
                    disabled={disabled}
                  />
                </div>
              );
            }
            if (obj.real) {
              return (
                <div className="container">
                  <TextField
                    id="outlined-basic"
                    defaultValue={data[obj.key]}
                    value={formatReal(parseFloat(data[obj.key]).toFixed(2))}
                    name={obj.key}
                    label={obj.label}
                    variant="outlined"
                    disabled={disabled}
                  />
                </div>
              );
            }
            if (obj.percentage) {
              return (
                <div className="container">
                  <TextField
                    id="outlined-basic"
                    defaultValue={data[obj.key]}
                    value={`${formatReal(
                      parseFloat(data[obj.key]).toFixed(2)
                    ).replace("R$", "")}%`}
                    name={obj.key}
                    label={obj.label}
                    variant="outlined"
                    disabled={disabled}
                  />
                </div>
              );
            }

            return (
              <div className="container">
                <TextField
                  id="outlined-basic"
                  defaultValue={data[obj.key]}
                  value={
                    format ? format(data[obj.key]) || "" : data[obj.key] || ""
                  }
                  name={obj.key}
                  label={obj.label}
                  variant="outlined"
                  disabled={disabled}
                />
              </div>
            );
          })}
      </div>

      <div
        style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}
      >
        <ReactIf
          condition={
            isTituloArrematado === "n" && maturitys && data && showAcceptBid()
          }
        >
          <ReactThen>
            <aside className="container-button">
              <ConfirmButton onClick={() => handleAcceptBidModal()}>
                Aceitar Lance
              </ConfirmButton>
            </aside>
          </ReactThen>
          <ReactCatchIf condition={canShowBidDetails()}>
            <aside className="container-button">
              <ConfirmButton onClick={() => showBidDetailsModal()}>
                Mostrar Detalhes
              </ConfirmButton>
            </aside>
          </ReactCatchIf>
        </ReactIf>
      </div>

      <BidDialog
        bid={data}
        open={bidDetailsModalOpen}
        handleClose={closeBidDetailsModal}
      />
    </div>
  );
};

export default Lance;
